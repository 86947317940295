import React, { useState } from 'react';
import './Dropdown.css';

export const getDayOfWeek = (date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
  };
  
  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };
  
  const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp.seconds * 1000);
  };
  
  const createTimeSlots = (startTimestamp, endTimestamp, numberMinutesResa, tableId) => {
    let startTime = convertTimestampToDate(startTimestamp);
    const endTime = convertTimestampToDate(endTimestamp);
    console.log("for table ", tableId, "start at ", startTimestamp, startTime, "end at ", endTimestamp, endTime);

    return { startTime, endTime, numberMinutesResa, tableId };
  };
  
const getAvailableTimeSlots = (tables, selectedDate) => {
    const dayOfWeek = getDayOfWeek(selectedDate).toLowerCase();
    let availableSlots = [];
  
    tables.forEach((table) => {
      const dayServices = table[dayOfWeek]?.services;
      if (table[dayOfWeek]?.isOpen && dayServices) {
        console.log("TABLE__:", table.id);
        dayServices.forEach((service) => {
          const slot = createTimeSlots(
            service.dateStartService,
            service.dateEndService,
            service.numberMinutesResa,
            table.id
          );
          availableSlots.push(slot);
        });
      }
    });
    console.log("AVAILABLE TIME SLOTS:", availableSlots);
    return availableSlots;
  };

  // Générer des créneaux horaires toutes les 15 minutes
  export function generateIntermediateSlots(slots) {
    const intermediateSlots = {};
    
    slots.forEach(slot => {
      let currentTime = new Date(slot.startTime); // Commencer à l'heure de début
      const endTime = new Date(slot.endTime); // Obtenir l'heure de fin
      const durationInMilliseconds = slot.numberMinutesResa * 60 * 1000;
      console.log("NB MINUTES RESAS_:", slot.numberMinutesResa);
      const lastValidSlotTime = new Date(endTime.getTime() - (durationInMilliseconds/2) + 1000); // Ajouter 1 seconde
  
      while (currentTime <= lastValidSlotTime) {  // Modifier la condition pour s'assurer que currentTime ne dépasse pas lastValidSlotTime
        // Formater l'heure pour l'utiliser comme clé dans l'objet intermediateSlots
        //console.log("WHILE CT<= LVS", currentTime, lastValidSlotTime);
        const timeKey = currentTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        
        // Vérifier si le créneau chevauche un autre créneau
        const overlappingSlot = Object.keys(intermediateSlots).some(existingTimeKey => {
          const existingSlotTime = new Date();
          const [hours, minutes] = existingTimeKey.split(':').map(Number)
          existingSlotTime.setHours(hours, minutes);
          return existingSlotTime >= currentTime && existingSlotTime < new Date(currentTime.getTime() + 15 * 60000);
        });
  
        // Si le créneau n'est pas chevauché et est avant le dernier créneau valide, ajouter à intermediateSlots
        if (!overlappingSlot && currentTime <= lastValidSlotTime) {
          // Si l'heure n'existe pas déjà, initialisez avec un tableau vide
          if (!intermediateSlots[timeKey]) {
            intermediateSlots[timeKey] = {
              tables: [],
              duration: slot.numberMinutesResa  // Fixer la durée à 15 minutes pour chaque créneau
            };
          }
          
          // Ajoutez le tableId à la liste des tables pour cette heure
          intermediateSlots[timeKey].tables.push(slot.tableId);
        }
  
        // Incrémentez le currentTime de 15 minutes
        currentTime = new Date(currentTime.getTime() + 15 * 60000);
      }
    });
  
    // Convertissez l'objet intermédiaire en tableau de la forme souhaitée
    const formattedSlots = Object.keys(intermediateSlots).map(timeKey => {
      return {
        time: timeKey,
        duration: intermediateSlots[timeKey].duration,
        tables: intermediateSlots[timeKey].tables
      };
    });
    console.log("Intermediate Slots:", JSON.stringify(formattedSlots, null, 2));

  
    return formattedSlots;
  }
  
  
// Convertir un horaire "hh:mm AM/PM" ou "hh:mm" (24h) en minutes depuis minuit
export const timeStringToMinutes = (timeString) => {
  const parts = timeString.match(/(\d+):(\d+)\s*(AM|PM)?/i);
  let [ , hours, minutes, modifier] = parts.map(part => part ? part : '');
  
  hours = parseInt(hours, 10);
  minutes = parseInt(minutes, 10);

  // Ajustez pour le format 12 heures avec AM/PM
  if (modifier.toUpperCase() === "PM" && hours < 12) {
    hours += 12;
  } else if (modifier.toUpperCase() === "AM" && hours === 12) {
    hours = 0;
  }

  return hours * 60 + minutes;
};

function minutesToTimeString(minutes) {
  const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
  const mins = (minutes % 60).toString().padStart(2, '0');
  return `${hours}:${mins}`;
}


export default getAvailableTimeSlots;